import logoDesktop from '../../../assets/logo-advertising-desktop.svg';
import logoMobile from '../../../assets/logo-advertising-mobile.svg';
import IconGeneric from './IconGeneric';
import classnames from 'classnames';
import {useContext, useMemo, useState} from 'react';
import {AppContext} from "../../../pages/App/Home";
import {COMITIUM_URL_KEY} from "../../../domain/Constants";

interface Props{
    isAdmin: boolean
}
const Logo = ({isAdmin} : Props) => {
    const [appsDropdown, setAppsDropdown] = useState<boolean>(false);
    const { accounts, selectedAccount } = useContext(AppContext);
    const comitiumUrl = useMemo(() => {
        if (isAdmin) {
            return null;
        }

        const storedUrl = localStorage.getItem(COMITIUM_URL_KEY);
        if (storedUrl) {
            return storedUrl;
        }

        try {
            const urlFromAccounts = selectedAccount?.comitium_url || accounts?.[0]?.comitium_url || null;
            return urlFromAccounts;
        } catch (error) {
            return null;
        }
    }, [isAdmin, accounts, selectedAccount])

    const showDropdown = useMemo(() => !!comitiumUrl, [comitiumUrl])

    return (
        <>
            <div className={classnames('cc-header__item-wrapper', { 'is-open': appsDropdown })}>
                <div className="cc-header__item mc-logo">
                    <img className="mc-logo__image--desktop" src={logoDesktop} alt={"Logo Comitium Advertising"} />
                    <img className="mc-logo__image--tablet" src={logoMobile} alt={"Logo Comitium Advertising"} />
                </div>
                <div className={classnames('cc-header__dropdown-arrow', { 'is-open': appsDropdown })}
                    onClick={() => { if (showDropdown) setAppsDropdown(!appsDropdown) }}
                >
                    {showDropdown && <IconGeneric iconName="arrow-down-ico" className="mc-icon__generic--size-12" />}
                </div>
                <>
                    <div className="cc-header__dropdown">
                        {comitiumUrl &&
                            <div className='cc-header__dropdown-item'>
                                <IconGeneric
                                    iconName="logo-comitium"
                                    className="mc-logo mc-logo__app--comitium"
                                    onClick={() => {
                                        window.open(comitiumUrl, "_self");
                                    }}
                                />
                            </div>
                        }
                    </div>
                </>
            </div>
        </>
    );
}

export default Logo;