import {useTranslation} from "react-i18next";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import React from "react";
import FiltersRevenueShare from "../../../components/BackOffice/Filters/RevenueShare/FiltersRevenueShare";
import RevenueShareGrid from "../../../components/BackOffice/RevenueShare/RevenueShareGrid";
import useBackofficeFilters from "../../../hooks/filters/use-backoffice-filters";
import useFiltersRevenueShareContext from "../../../hooks/context/use-filters-revenue-context";
import useBackofficeRevenueShareData from "../../../hooks/use-backoffice-revenue-share-data";
import Heading from "../../../components/Common/Heading";
import usePaginationContext from "../../../hooks/context/use-pagination-context";

const RevenueShareBackOffice = () => {
    const {t} = useTranslation();
    const {dateRange, filteredSubsites} = useFiltersRevenueShareContext();
    const paginationContextData = usePaginationContext();
    const { subsites} = useBackofficeFilters()
    const { revenueShares, loading } = useBackofficeRevenueShareData(
        dateRange.start,
        dateRange.end,
        filteredSubsites
    )

    if (loading) {
        return <LoadingFull/>
    }

    return(
        <>
            <Heading title={t('revenue-shares')} />
            <div className="cc-card cc-card--invoice">
                <FiltersRevenueShare dateRange={dateRange} subsites={subsites}/>
                <RevenueShareGrid
                    subsites={subsites}
                    revenueShares={revenueShares}
                    paginationSettings={
                        {
                            key: 'revenue-shares',
                            rowsPerPage: paginationContextData.getRowsPerPage('revenue-shares') ?? 10,
                            rowsPerPageOptions: [10, 20, 30, 40, 50]
                        }
                    }
                />
            </div>
        </>
    )
}

export default RevenueShareBackOffice