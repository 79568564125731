export type DateRangeOptions = 'custom'|'last_7_days'|'last_30_days'|'current_month'|'last_month';

export interface DateRange {
    label: DateRangeOptions
    start: Date|null
    end: Date|null
}

export const dateRangeOptions: DateRangeOptions[] = [
    'last_7_days',
    'last_30_days',
    'current_month',
    'last_month',
];
