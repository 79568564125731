import {useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {DateRange, DateRangeOptions} from "../domain/DateRange";
import {GeneralData} from "../domain/StatsData";
import {FilterOption} from "../domain/FilterOption";
import useDates from "./use-dates";

interface Response {
    generalData: BackOfficeGeneralData,
    loading: boolean
}

interface GetDataParameters {
    label: DateRangeOptions
    from?: string
    to?: string
    account?: number
    pids?: string
    partner_ids?: string
}

export interface BackOfficeGeneralData {
    days: GeneralData[]
    summary: GeneralData
}

const initialSummaryData: GeneralData = {
    impressions:0,
    income: 0,
    impressions_dashboard: 0,
    income_dashboard: 0,
    ecpm: 0,
    viewability: 0,
    differential: 0,
    differential_percentage: 0
};

const initialData: BackOfficeGeneralData = {
    days: [],
    summary: initialSummaryData,
}

const useBackOfficeGeneralData = (
    dateRange: DateRange,
    filteredSubsites: FilterOption[],
    filteredPartners: FilterOption[],
    filteredDevices: FilterOption[],
    filteredEnvironments: FilterOption[],

) : Response => {
    const [loading, setLoading] = useState(true)
    const {formatDateCalendarPicker} = useDates()
    const [generalData, setGeneralData] = useState<BackOfficeGeneralData>(initialData)
    const client = useMemo(() => new ApiClient(), [])

    const parameters: GetDataParameters = useMemo(() => {
        const params = {
            label: dateRange.label,
        }

        if (dateRange.start) {
            Object.assign(params, {from: formatDateCalendarPicker(dateRange.start)})
        }

        if (dateRange.end) {
            Object.assign(params, {to: formatDateCalendarPicker(dateRange.end)})
        }

        if (filteredSubsites.length > 0) {
            Object.assign(params, {pids: filteredSubsites.map((subsite) => subsite.id).join(',')})
        }

        if (filteredSubsites.length > 0) {
            Object.assign(params, {pids: filteredSubsites.map((subsite) => subsite.id).join(',')})
        }

        if (filteredPartners.length > 0) {
            Object.assign(params, {partner_ids: filteredPartners.map((partner) => partner.id).join(',')})
        }

        if (filteredDevices.length > 0) {
            Object.assign(params, {device_ids: filteredDevices.map((device) => device.id).join(',')})
        }

        if (filteredEnvironments.length > 0) {
            Object.assign(params, {environment_ids: filteredEnvironments.map((environment) => environment.id).join(',')})
        }

        return params
    }, [dateRange, filteredSubsites, filteredPartners, filteredDevices, filteredEnvironments, formatDateCalendarPicker])

    useEffect(() => {
        setLoading(true);
        client
            .getMetricsStats('general', parameters)
            .then((response) => {
                const generalData: BackOfficeGeneralData = response.data;
                setGeneralData({ days: generalData.days, summary: generalData.summary });
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [client, parameters, setLoading]);


    return {
        generalData,
        loading
    }
}

export default useBackOfficeGeneralData