import {createContext, Dispatch, SetStateAction,  useContext, useState} from "react";
import useDates from "../use-dates";
import {DateRange} from "../../domain/DateRange";
import {FilterOption} from "../../domain/FilterOption";

export interface FiltersContextInterface {
    dateRange: DateRange
    setDateRange: Dispatch<SetStateAction<DateRange>>,
    dateRangeInvoices: DateRange
    setDateRangeInvoices: Dispatch<SetStateAction<DateRange>>
    filteredSubsites: FilterOption[]
    setFilteredSubsites: Dispatch<SetStateAction<FilterOption[]>>
    filteredPartners: FilterOption[]
    setFilteredPartners: Dispatch<SetStateAction<FilterOption[]>>
    filteredInvoiceGroups: FilterOption[]
    setFilteredInvoiceGroups: Dispatch<SetStateAction<FilterOption[]>>
    filteredDevices: FilterOption[]
    setFilteredDevices: Dispatch<SetStateAction<FilterOption[]>>,
    filteredEnvironments: FilterOption[]
    setFilteredEnvironments: Dispatch<SetStateAction<FilterOption[]>>
    invoiceReference: string
    setInvoiceReference: Dispatch<SetStateAction<string>>
    filterableInvoiceStates: FilterOption[]
    setFilterableInvoiceStates: Dispatch<SetStateAction<FilterOption[]>>
    filteredInvoiceStates: FilterOption[]
    setFilteredInvoiceStates: Dispatch<SetStateAction<FilterOption[]>>
}

export const FiltersContext = createContext<FiltersContextInterface>({} as FiltersContextInterface)

const useFiltersContext = () => useContext(FiltersContext)

export const useFiltersContextData = (): FiltersContextInterface => {
    const [dateRange, setDateRange] = useState<DateRange>({
        label: 'last_7_days',
        start: null,
        end: null
    })

    const {getFirstDayOfMonth, getLastDayOfMonth} = useDates()
    const [dateRangeInvoices, setDateRangeInvoices] = useState<DateRange>({
        'label': 'custom',
        'start': getFirstDayOfMonth(new Date()),
        'end': getLastDayOfMonth(new Date()),
    })

    const [filteredSubsites, setFilteredSubsites] = useState<FilterOption[]>([]);
    const [filteredPartners, setFilteredPartners] = useState<FilterOption[]>([]);
    const [filteredInvoiceGroups, setFilteredInvoiceGroups] = useState<FilterOption[]>([]);
    const [filteredDevices, setFilteredDevices] = useState<FilterOption[]>([]);
    const [filteredEnvironments, setFilteredEnvironments] = useState<FilterOption[]>([]);
    const [invoiceReference, setInvoiceReference] = useState<string>("");
    const [filterableInvoiceStates, setFilterableInvoiceStates] = useState<FilterOption[]>([]);
    const [filteredInvoiceStates, setFilteredInvoiceStates] = useState<FilterOption[]>([]);

    return {
        dateRange,
        setDateRange,
        dateRangeInvoices,
        setDateRangeInvoices,
        filteredSubsites,
        setFilteredSubsites,
        filteredPartners,
        setFilteredPartners,
        filteredInvoiceGroups,
        setFilteredInvoiceGroups,
        filteredDevices,
        setFilteredDevices,
        filteredEnvironments,
        setFilteredEnvironments,
        invoiceReference,
        setInvoiceReference,
        filterableInvoiceStates,
        setFilterableInvoiceStates,
        filteredInvoiceStates,
        setFilteredInvoiceStates
    }
}

export default useFiltersContext