import {
    DataGrid
} from '@mui/x-data-grid';
import {useTranslation} from "react-i18next";
import AddItemToolbar from '../Grid/Toolbar/AddItemToolbar';
import { Alert, Snackbar } from '@mui/material';
import ConfirmDialog from "../../Common/Modal/ConfirmDialog";
import {RevenueShareData} from "../../../domain/RevenueShareData";
import {useRevenueShareGrid} from "../../../hooks/ui/use-revenue-share-grid";
import {Subsite} from "../../../domain/Subsite";
import {usePaginationGrid} from "../../../hooks/ui/use-pagination-grid";

interface Props {
    subsites: Subsite[]
    revenueShares: RevenueShareData[];
    paginationSettings?: { key: string, rowsPerPage: number, rowsPerPageOptions: any }
}

export default function RevenueShareGrid({subsites, revenueShares, paginationSettings}: Props) {
    const {t} = useTranslation();
    const {
        handleRowEditStop,
        handleRowModesModelChange,
        handleProcessRowUpdateError,
        processRowUpdate,
        onConfirmDelete,
        rows,
        columns,
        setRows,
        rowModesModel,
        setRowModesModel,
        deleteDialog,
        snackbar,
        closeSnackbar
    } = useRevenueShareGrid(subsites, revenueShares, true);

    const {handlePageSizeChange, rowsPerPage} = usePaginationGrid(paginationSettings)

    return (<>
        <div className="cc-fra__table">
            <DataGrid
                sx={{ border: 0 }}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                localeText={{
                    noRowsLabel: t('no-results'),
                    MuiTablePagination: {
                        labelRowsPerPage: t('rows-per-page'),
                    },
                }}
                slots={{
                    toolbar: AddItemToolbar,
                }}
                slotProps={{
                    toolbar: {rows, setRows, setRowModesModel, allowEditingActionsToolbar: true},
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'position', sort: 'asc' }],
                    },
                    pagination: { paginationModel: { pageSize: rowsPerPage ?? 10 } },
                }}
                pageSizeOptions={paginationSettings?.rowsPerPageOptions ?? [10, 20, 30, 40, 50]}
                onPaginationModelChange={(params) => {
                    handlePageSizeChange(params);
                }}
                sortingOrder={['desc', 'asc']}
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={closeSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={closeSnackbar} />
                </Snackbar>
            )}
            <ConfirmDialog
                open={deleteDialog}
                title={t('delete row')}
                message={t('delete row message')}
                onConfirm={onConfirmDelete}
            />
        </div>
    </>);
}