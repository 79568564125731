import {StatsContext} from "../../../hooks/context/use-stats-context";
import Filters from "../../../components/Common/Filters/Filters";
import GeneralViewBackOffice from "../../../components/BackOffice/Stats/General/GeneralViewBackOffice";
import GeneralChartBackOffice from "../../../components/BackOffice/Stats/General/GeneralChartBackOffice";
import GeneralTableDataBackOffice from "../../../components/BackOffice/Stats/General/GeneralTableDataBackOffice";
import useFiltersContext from "../../../hooks/context/use-filters-context";
import useBackOfficeGeneralData from "../../../hooks/use-backoffice-general-data";
import LoadingFull from "../../../components/Common/Loading/LoadingFull";
import {useTranslation} from "react-i18next";
import Heading from "../../../components/Common/Heading";

const ListGeneralDataBackOffice = () => {
    const {t} = useTranslation();
    const filtersContextData = useFiltersContext();
    const {generalData, loading} = useBackOfficeGeneralData(
        filtersContextData.dateRange,
        filtersContextData.filteredSubsites,
        filtersContextData.filteredPartners,
        filtersContextData.filteredDevices,
        filtersContextData.filteredEnvironments
    )

    if (loading) {
        return <LoadingFull/>
    }

    return (
        <StatsContext.Provider value={{generalData: generalData}}>
            <Heading title={t('general')} />
            <Filters/>
            <GeneralViewBackOffice data={generalData?.summary}/>
            <GeneralChartBackOffice data={generalData?.days}/>
            <GeneralTableDataBackOffice data={
                {
                    days: generalData?.days,
                    summary: generalData?.summary
                }
            }/>
        </StatsContext.Provider>
    )

}
export default ListGeneralDataBackOffice