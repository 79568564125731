import { useTranslation } from "react-i18next";
import useNumbers from "../../../../hooks/use-numbers";
import useFiltersContext from "../../../../hooks/context/use-filters-context";
import GeneralViewCard from "../../../Common/GeneralView/GeneralViewCard";
import GeneralViewComponent from "../../../Common/GeneralView/GeneralViewComponent";
import {GeneralData} from "../../../../domain/StatsData";

interface Props {
    data: GeneralData|undefined
}
const GeneralView = ({data}: Props) => {
    const { i18n, t } = useTranslation();
    const {dateRange} = useFiltersContext();
    const { formatCompactNumber, formatCurrency, formatPercent, formatCompactCurrency } = useNumbers()

    return (
        <>
            <GeneralViewComponent title={t('general-view')} text={t(dateRange.label)}>
                <GeneralViewCard title={t('displays')} value={formatCompactNumber(data?.impressions ?? 0, i18n.language, 2)}/>
                <GeneralViewCard title={t('income')} value={formatCompactCurrency(data?.income ?? 0, i18n.language, 2)}/>
                <GeneralViewCard title="ECPM" value={formatCurrency(data?.ecpm ?? 0, i18n.language, 2)}/>
                <GeneralViewCard title={t('viewability')} value={formatPercent(data?.viewability ?? 0, i18n.language, 2)}/>
            </GeneralViewComponent>
            <GeneralViewComponent title={' '} text={' '}>
                <GeneralViewCard title={t('displays dashboard')} value={formatCompactNumber(data?.impressions_dashboard ?? 0, i18n.language, 2)}/>
                <GeneralViewCard title={t('income dashboard')} value={formatCompactCurrency(data?.income_dashboard ?? 0, i18n.language, 2)}/>
                <GeneralViewCard title={t('differential')} value={formatCurrency(data?.differential ?? 0, i18n.language, 2)}/>
                <GeneralViewCard title={t('differential-percentage')} value={formatPercent(data?.differential_percentage ?? 0, i18n.language, 2)}/>
            </GeneralViewComponent>
        </>
    );
}

export default GeneralView;
