import {
    utFormatCompactCurrency,
    utFormatCompactNumber,
    utFormatCurrency,
    utFormatNumber,
    utFormatPercent
} from "../utils/number";

const useNumbers = () => {

    function formatNumber(number: number|undefined, locale = 'es-ES', numberDecimals = 0): string {
        return utFormatNumber(number, locale, numberDecimals);
    }

    function formatCompactNumber(number: number|undefined, locale = 'es-ES', numberDecimals = 2): string {
        if (number === 0) {
            return '0';
        } else if (!number) {
            return '-';
        }
        return utFormatCompactNumber(number, locale, numberDecimals);
    }

    function formatCompactCurrency(number: number|undefined, locale = 'es-ES', numberDecimals = 2): string {
        if (number === 0) {
            return '0';
        } else if (!number) {
            return '-';
        }
        return utFormatCompactCurrency(number, locale, numberDecimals);
    }

    function formatCurrency(number: number|undefined, locale = 'es-ES', numberDecimals = 2): string {
        return utFormatCurrency(number, locale, numberDecimals);
    }

    function formatPercent(number: number|undefined, locale = 'es-ES', numberDecimals = 2): string {
        return utFormatPercent(number, locale, numberDecimals);
    }

    return {
        formatCurrency,
        formatCompactNumber,
        formatCompactCurrency,
        formatNumber,
        formatPercent
    }
}

export default useNumbers;