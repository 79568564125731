import {Outlet, useNavigate} from "react-router-dom";
import {createContext, Dispatch, SetStateAction, useState} from "react";
import Header from "../../components/Common/Header/Header";
import LoadingFull from "../../components/Common/Loading/LoadingFull";
import {useAccounts} from "../../hooks/use-accounts";
import {Account} from "../../domain/Account";
import Sidebar from "../../components/Common/Sidebar";
import {FiltersContext, useFiltersContextData} from "../../hooks/context/use-filters-context";
import { useBackofficeAuthentication } from "../../hooks/use-backoffice-authentication";
import {PaginationContext, usePaginationContextData} from "../../hooks/context/use-pagination-context";
import Advise from "../../components/App/Common/UI/Advise";

interface AppContextInterface {
    selectedAccount: Account|null
    setSelectedAccount: Dispatch<SetStateAction<Account|null>>
    accounts: Account[]
}

export const AppContext = createContext<AppContextInterface>({} as AppContextInterface)

const Home = () => {
    const filtersContextData = useFiltersContextData();
    const paginationContextData = usePaginationContextData();
    const [selectedAccount, setSelectedAccount] = useState<Account|null>(null)
    const {accounts} = useAccounts();
    const {isAdmin} = useBackofficeAuthentication()
    const navigate = useNavigate();

    const data = {
        selectedAccount,
        setSelectedAccount,
        accounts
    }

    if (isAdmin && accounts.length === 0) {
        navigate('/backoffice')
    }

    if (!data || accounts.length === 0) {
        return <LoadingFull/>;
    }

    return (
        <AppContext.Provider value={data}>
            <FiltersContext.Provider value={filtersContextData}>
                <PaginationContext.Provider value={paginationContextData}>
                    <div className="comitium-advertising" data-testid="home">
                        <Header />
                        <div className="oc-flex oc-flex--no-wrap cc-main__wrapper">
                            <Sidebar/>
                            <div className="cc-main">
                                <Advise/>
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </PaginationContext.Provider>
            </FiltersContext.Provider>
        </AppContext.Provider>
    );
}
export default Home