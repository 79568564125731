import LineMainChart from "../../../Common/Chart/LineMainChart";
import {useMemo} from "react";
import {GeneralData} from "../../../../domain/StatsData";

interface Props {
    data: GeneralData[] | undefined,
}

const GeneralChartBackOffice = ({data}: Props) => {

    const chartData = useMemo(() => data ? Object.entries(data).map(([dateStr, day]) => {
        const stat: GeneralData = day;

        return {
            date: dateStr,
            impressions: stat.impressions ?? null,
            income: stat.income ?? null,
            ecpm: stat.ecpm,
            viewability: stat.viewability
        }
    }
    ).reverse() : [], [data])

    return (
        <>
            {chartData.length > 0 && <LineMainChart chart={chartData} />}
        </>
    );
}

export default GeneralChartBackOffice;