import React from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from 'react-router-dom';
import IconGeneric from "../../Common/Icon/IconGeneric";
import {SidebarItem} from "../../../domain/SidebarItem";

const sidebarItems: SidebarItem[] = [
    {
        pathname: '/backoffice',
        to: '/backoffice',
        label: 'general',
        icon: 'home-ico',
    },
    {
        pathname: '/backoffice/historical',
        to: '/backoffice/historical',
        label: 'historical',
        icon: 'historical-ico',
    },
    {
        pathname: '/backoffice/invoices',
        to: '/backoffice/invoices',
        label: 'invoices',
        icon: 'invoice-ico',
    },
    {
        pathname: '/backoffice/invoices/pending',
        to: '/backoffice/invoices/pending',
        label: 'pending invoices',
        icon: 'pending-invoice-ico',
    },
    {
        pathname: '/backoffice/invoice-groups',
        to: '/backoffice/invoice-groups',
        label: 'invoice-groups',
        icon: 'invoice-group-ico',
    },
    {
        pathname: '/backoffice/projects',
        to: '/backoffice/projects',
        label: 'projects',
        icon: 'projects-ico',
    },
    {
        pathname: '/backoffice/revenue-share',
        to: '/backoffice/revenue-share',
        label: 'revenue-shares',
        icon: 'revenue-ico',
    },
];

const SidebarItemsListBackOffice = () => {
    const {t} = useTranslation()
    const {pathname} = useLocation();

    return (
        <>
            {sidebarItems.map((item: SidebarItem) => (
                <Link key={item.pathname} className={`cc-sidebar__item ${item.pathname === pathname ? 'is-active' : ''}`} to={item.to}>
                    <IconGeneric iconName={item.icon} className="mc-icon__generic--size-16" />{t(item.label)}
                </Link>
            ))}
        </>
    );
}

export default SidebarItemsListBackOffice