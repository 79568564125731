import { useContext, useEffect, useMemo, useState} from "react";
import ApiClient from "../client/ApiClient";
import {AppContext} from "../pages/App/Home";
import {DateRange, DateRangeOptions} from "../domain/DateRange";
import {GeneralData} from "../domain/StatsData";
import useDates from "./use-dates";

interface Response {
    data: AppGeneralData
    loading: boolean
}

interface GetDataParameters {
    label: DateRangeOptions
    from?: string
    to?: string
    account?: number
}

export interface AppGeneralData {
    days: GeneralData[]
    summary: GeneralData
}


const initialSummaryData: GeneralData = {
    impressions_dashboard: 0,
    income_dashboard: 0,
    ecpm: 0,
    viewability: 0,
};

const initialData: AppGeneralData = {
    days: [],
    summary: initialSummaryData,
}

const useAppGeneralData = (
    dateRange: DateRange
): Response => {
    const [loading, setLoading] = useState(true)
    const {formatDateCalendarPicker} = useDates()
    const {accounts, selectedAccount} = useContext(AppContext)
    const [generalData, setGeneralData] = useState<AppGeneralData>(initialData)
    const client = useMemo(() => new ApiClient(), [])

    const pids =  useMemo(() => {
        if(selectedAccount){
            return selectedAccount.project_id
        }else{
            return (accounts.map((account => account.project_id))).join(',');
        }
    }, [accounts, selectedAccount])


    const parameters: GetDataParameters = useMemo(() => {
        const params = {
            label: dateRange.label,
            pids: pids
        }

        if (dateRange.start) {
            Object.assign(params, {from: formatDateCalendarPicker(dateRange.start)})
        }

        if (dateRange.end) {
            Object.assign(params, {to: formatDateCalendarPicker(dateRange.end)})
        }

        return params
    }, [dateRange, pids, formatDateCalendarPicker])


    useEffect(() => {
        setLoading(true);
        client
            .getMetricsStats('general', parameters)
            .then((response) => {
                const generalData: AppGeneralData = response.data;
                setGeneralData({days: generalData.days, summary:generalData.summary})
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [client, parameters, setLoading])

    return {
        data: generalData,
        loading
    }
}

export default useAppGeneralData